import VoteME from "./img/vote.png"
import QuizIT from "./img/quiz.png"

const Projects = () => {
    return ( 
        <div className="max-w-2xl mx-auto mt-10">
            <h1 className={`font-semibold text-center mb-5 text-3xl text-white relative after:bg-white after:w-[10%]`}>Projects</h1>
            <div className="flex gap-3 bg-white border border-coal mb-5 rounded-xl overflow-hidden items-center justify-start">
                <div className="relative w-32 h-32 flex-shrink-0">
                    <img className="absolute left-0 top-0 w-fill mt-9 object-cover object-center transition duration-50" loading="lazy" src={QuizIT} />
                </div>

                <div className="flex flex-col gap-2 py-2">

                    <p className="text-xl font-bold">QuizIT - Quality Understanding and Interactive Zone for Instructional Testing</p>

                    <p className="text-coal text-justify mr-3">
                    is an exam system designed to enhance student learning through interactive assessments. With real-time tracking and detailed analytics, QuizIT enables instructors to monitor progress and provide targeted feedback. Students benefit from instant results and personalized insights, making QuizIT a powerful tool for effective, engaging, and data-driven learning.
                    </p>

                    <span className="flex items-center justify-start text-coal">
                    <i className="fa-solid fa-link"></i>
                        <a classNameName="ml-3" href="http://lightsdigisoft.online/quizit" target="_blank">lightsdigisoft.online/quizit</a>
                    </span>
                </div>
            </div>

            <div className="flex gap-3 bg-white border border-coal mb-5 rounded-xl overflow-hidden items-center justify-start">
                <div className="relative w-32 h-32 flex-shrink-0">
                    <img className="absolute left-0 top-0 w-fill mt-9 object-cover object-center transition duration-50" loading="lazy" src={VoteME} />
                </div>

                <div className="flex flex-col gap-2 py-2">

                    <p className="text-xl font-bold">VoteME</p>

                    <p className="text-coal text-justify mr-3">
                    is a fun and engaging platform designed for pageants, games, and contests where users can vote on who or which is better. Whether it’s for friendly competition or informal voting, VoteME provides a simple, interactive experience for participants and voters alike, making it easy to track results in real time. Perfect for events where fun and excitement are the main focus!
                    </p>

                    <span className="flex items-center justify-start text-coal">
                    <i className="fa-solid fa-link"></i>
                        <a classNameName="ml-3" href="http://lightsdigisoft.online/quizit" target="_blank">lightsdigisoft.online/voteme</a>
                    </span>
                </div>
            </div>
        </div>
     );
}
 
export default Projects;