import React, { useEffect } from 'react';

const Typewriter = ({ id,it }) => {
  useEffect(() => {
    const words = ["a Web Developer", "a Software Developer", "a Back End Developer", `${it} IT Specialist`];
    let i = 0;
    let j = 0;
    let isDeleting = false;

    const type = () => {
      const element = document.getElementById(id);
      if (!element) {
        console.error(`Element with ID "${id}" not found`);
        return;
      }

      const currentWord = words[i];
      if (isDeleting) {
        j--; 
        if (j === 0) {
          isDeleting = false;
          i = (i + 1) % words.length;
        }
      } else {
        j++; 
        if (j === currentWord.length) {
          isDeleting = true;
        }
      }

      element.textContent = currentWord.substring(0, j); 
      setTimeout(type, isDeleting ? 50 : 100);
    };

    type();

    return () => {
      clearTimeout(type);
    };
  }, [id,it]);

  return (
    <div className="w-full h-full flex">
      <h1 id={id} it={it} className="text-4xl font-bold"></h1>
    </div>
  );
};

export default Typewriter;
