import lds from "./img/lds.png"

const Footer = () => {
    return ( 
        <div className="text-center bg-white text-coal py-[2%]">
            <a href="#" className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900">
                <img src={lds} className="h-12 mr-3 sm:h-9" alt="Landwind Logo" />
                LightsDigiSoft
            </a>

            <span className="block text-sm text-center text-gray-500">© 2024 LightsDigiSoft.
                <br />
                Developer:
                <a href="https://www.facebook.com/LightsOfFrancis"
                    className="text-purple-600 hover:underline"> LightsOfFrancis
                </a>.
            </span>
        </div>
     );
}
 
export default Footer;