const Contact = () => {
    return ( 
        <div className="max-w-2xl mx-auto mt-10 text-center flex flex-col items-center">
            <h1 className="font-semibold text-center mb-5 text-3xl text-white relative after:bg-white after:w-[10%]">
                Contacts
            </h1>
            <div className="grid grid-cols-3 gap-3 justify-center">
                <a
                    href="#https://www.messenger.com/t/100021055253603"
                    aria-label="GITHUB"
                    target="_blank"
                    rel="noopener"
                    className="mr-5 w-[100%] flex items-center hover:underline bg-white px-1 py-1 rounded-md text-black transition hover:translate-x-2 hover:no-underline"
                >
                    <i class="fa-brands fa-facebook-messenger text-blue-light"></i>
                    <span className="ml-2">Messenger</span>
                </a>
                <a
                    href="https://www.linkedin.com/in/francisco-martinez-0a68391ba/?trk=li_LOL_DA_global_careers_jobsgtm_otwGeneral_res_Sep2023_dav2"
                    aria-label="GITHUB"
                    target="_blank"
                    rel="noopener"
                    className="w-[100%] flex items-center hover:underline bg-white px-1 py-1 rounded-md text-black transition hover:translate-x-2 hover:no-underline"
                >
                    <i class="fa-brands fa-linkedin text-blue-light"></i>
                    <span className="ml-2">LinkIn</span>
                </a>
                <a
                    href="https://mail.google.com/mail/u/0/#inbox"
                    aria-label="GITHUB"
                    target="_blank"
                    rel="noopener"
                    className="w-[100%] flex items-center hover:underline bg-white px-1 py-1 rounded-md text-black transition hover:translate-x-2 hover:no-underline"
                >
                    <i class="fa-solid fa-envelope text-blue-light"></i>
                    <span className="ml-2">Email</span>
                </a>
            </div>
        </div>

     );
}
 
export default Contact;