import Me from "./img/me2.png"
import Typewriter from "./js/typewriter.js"
const resume = () => {
    return ( 
        <div className="bg-white p-4">
            <div className="bg-white">
                <div className="flex rounded-t-lg bg-blue-light text-white sm:px-2 w-full">
                    <div className="h-40 w-40 overflow-hidden sm:rounded-full sm:relative sm:p-0 top-10 left-5 p-3">
                        <img src={Me} />
                    </div>
                    <div className="w-2/3 sm:text-center pl-5 mt-10 text-start">
                        <p className="font-poppins font-bold text-heading sm:text-4xl text-2xl">
                            Francisco P. Martinez
                        </p>
                        <p className="text-heading">Software Developer | Web Developer</p>
                    </div>
                </div>
                <div className="p-5">
                    <div className="flex flex-col sm:flex-row sm:mt-10">
                        <div className="flex flex-col sm:w-1/3">
                            <div className="py-3 sm:order-none order-3">
                                <h2 className="text-lg font-poppins font-bold text-blue">Contact</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>

                                <div>
                                    <div className="flex items-center my-1">
                                    <i className="fa-brands fa-linkedin text-blue"></i>
                                        <div className="ml-2 truncate">LightsDigiSoft</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i className="fa-solid fa-mobile text-blue"></i>
                                        <div className="ml-2 truncate"> 052 727 2789</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i className="fa-brands fa-facebook text-blue"></i>
                                        <div className="ml-2 truncate">Kiko Mtz</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i className="fa-solid fa-envelope  text-blue"></i>
                                        <div className="ml-2 truncate">franciscopalosmartinez@gmail.com</div>
                                    </div>

                                </div>
                            </div>
                            <div className="py-3 sm:order-none order-2">
                                <h2 className="text-lg font-poppins font-bold text-blue">Skills</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>

                                <div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-brands fa-php text-blue"></i>
                                        <div className="ml-2 truncate">PHP</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-brands fa-react text-blue"></i>
                                        <div className="ml-2 truncate">React</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-brands fa-js text-blue"></i>
                                        <div className="ml-2 truncate">JS</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-solid fa-code text-blue"></i>
                                        <div className="ml-2 truncate">C#</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-solid fa-database text-blue"></i>
                                        <div className="ml-2 truncate">MySQL, MSSql</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-solid fa-code text-blue"></i>
                                        <div className="ml-2 truncate">Tailwind CSS</div>
                                    </div>
                                    <div className="flex items-center my-1">
                                    <i class="fa-solid fa-microchip text-blue"></i>
                                        <div className="ml-2 truncate">Tech Support</div>
                                    </div>
                                </div>
                            </div>
                            <div className="py-3 sm:order-none order-1">
                                <h2 className="text-lg font-poppins font-bold text-blue">Education Background</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>
                                <div className="flex flex-col space-y-1">
                                    <div className="flex flex-col">
                                        <p className="font-semibold text-xs text-gray-700">2023</p>
                                        <p className="text-sm font-medium">
                                            <span className="text-green-700">B.S. (INFORMATION TECHNOLOGY)</span><br />Aldersgate College
                                        </p>
                                        {/* <p className="font-bold text-xs text-gray-700 mb-2">Percentage: 1.7</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col sm:w-2/3 order-first sm:order-none sm:-mt-10">
                            <div className="py-3">
                                <h2 className="text-lg font-poppins font-bold text-blue">About Me</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>
                                <p>Skilled Software Developer and IT Specialist with expertise in PHP, JavaScript (Next.js), TypeScript, C#, and both front-end (CSS, HTML, React) and back-end systems (MySQL, MSSQL). I enjoy troubleshooting computers and resolving IT issues across hardware, software, and networks, bringing efficiency and reliability to every environment I support.
                                I’m passionate about learning and collaboration, actively helping colleagues and students achieve shared goals. With strong time management and problem-solving skills, I thrive in fast-paced settings, delivering high-quality support and innovative solutions.</p>
                            </div>
                            <div className="py-3">
                                <h2 className="text-lg font-poppins font-bold text-blue">Professional Experience</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>

                                <div className="flex flex-col">

                                    <div className="flex flex-col">
                                        <p className="text-lg font-bold text-gray-700">IT Instructor | Software Programmer</p>
                                        <p className="font-semibold text-sm text-gray-700">2023 - 2024</p>
                                        <p className="font-semibold text-sm text-gray-700 mt-2 mb-1">Key Responsibilities</p>
                                        <ul className="text-sm list-disc pl-4 space-y-1">
                                            <li>As an IT Instructor and Software Developer, I successfully contributed in enhancing the Computer-Aided Audit, Review, and Testing System (CAARTS), improving audit efficiency through innovative solutions. I delivered comprehensive curricula, equipping students with practical IT skills for real-world applications.</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                            <div className="py-3">
                                <h2 className="text-lg font-poppins font-bold text-blue">Certificate</h2>
                                <div className="border-2 w-20 border-bl text-blue my-3"></div>

                                <div className="flex flex-col mt-8">
                                        <p className="text-lg font-bold text-gray-700">Mikrotik</p>
                                        {/* <p className="font-semibold text-sm text-gray-700">2020-2021</p> */}
                                        {/* <p className="font-semibold text-sm text-gray-700 mt-2 mb-1">Key Responsibilities</p> */}
                                        <ul className="text-sm list-disc pl-4 space-y-1">
                                            <li>MikroTik Certified Network Associate</li>
                                            <li>MikroTik Certified Routing Engineer</li>
                                            <li>MikroTik Certified User Management Engineer</li>
                                        </ul>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default resume;