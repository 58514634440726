import Me from "./img/me.png"
import Typewriter from "./js/typewriter.js"
const header = () => {
    const screensize = (act,value) => {
        return `md:${act}-[${value}] lg:${act}-[${value}] xl:${act}-[${value}] 2xl:${act}-[${value}] 3xl:${act}-[${value}]`;
      };
    return ( 
        <div className={`sticky top-0 h-screen flex flex-col py-[12%] ${screensize('py', '100%')} bg-white`}>
            <h1 className={`font-semibold text-6xl ml-[1%] ${screensize('ml', '35%')} mb-8 text-coal relative after:bg-white after:w-[10%]`}>Francisco P. Martinez</h1>
            <section
            className="relative bg-gradient-to-b from-blue-light to-white h-[300px] "
            >
            <div className="absolute w-full bottom-0 mx-auto px-4">
                <div className="  container mx-auto ">
                <div className=" grid lg:grid-cols-3  lge:grid-cols-3 md:grid-cols-1 mdsm:grid-cols-1 sm:grid-cols-1 ">
                    <div>
                    <div className="hidden md:block lg:block xl:block 2xl:block 3xl:block">
                        <div className="relative">
                            <img
                                src={Me}
                                alt="person"
                                className="h-[500px] w-full object-cover"
                            />
                            <div className="absolute bottom-0 left-0 right-0 h-[30%] bg-gradient-to-b from-transparent to-white"></div>
                        </div>
                    </div>
                    </div>
                    <div className=" col-span-2 relative ml-8">
                    <div className=" absolute bottom-0 py-10">
                        <h3 className="text-coal relative after:bg-[#fff] after:w-[10%] text-4xl font-semibold  pb-4 ">
                        I am <Typewriter id="myTypewriter" it="an" />
                        </h3>
                        <h4 className=" text-black  text-[20px] ">
                        "Always look back from the past because your past makes you who you are"
                        </h4>
                        <br />
                        <a href='https://www.facebook.com/LightsOfFrancis' className=" bg-blue-dark text-white rounded-lg  py-2 px-16 mt-4 hover:bg-white hover:text-black ">
                        Contact me
                        </a>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </div>
     );
}
 
export default header;