import Resume from "./resume.js"
import Projects from "./projects.js"
import Contact from "./contact.js"
const pages = () => {
    return ( 
        <div class="sticky top-0 h-auto flex flex-col items-center justify-center bg-gradient-to-b from-white to-blue-dark">
            <div className="pages">
                <section id="resume" className="resume">
                    <Resume />
                </section>
                {/* <section id="expertise" className="expertise"></section> */}
                <section id="projects" className="projects">
                    <Projects />    
                </section>
                <section id="contact" className="contact">
                    <Contact />
                </section>
            </div>
        </div>
     );
}
 
export default pages;