import ldslogo from "./img/lds.png"

const nav = () => {
    const toggleMenu = () => {
        const menu = document.getElementById("menu");
        menu.classList.toggle("hidden");
    };
    return (
        <nav className="fixed top-0 left-0 right-0 z-50 bg-white bg-opacity-90 backdrop-blur-md">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between h-16">
                <div className="flex-shrink-0">
                <a href="#home" className="flex items-center">
                    <img src={ldslogo} className="h-6 mr-3 sm:h-9 rounded-full" alt="Landwind Logo" />
                    <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Lights DigiSoft</span>
                </a>
                </div>
                <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                    {/* <a href="#home" className="text-sm font-medium text-gray-900 hover:text-gray-600 px-3 py-2 rounded-md transition-colors duration-300">Home</a> */}
                    <a href="#resume" className="text-sm font-medium text-gray-900 hover:text-gray-600 px-3 py-2 rounded-md transition-colors duration-300">Resume</a>
                    {/* <a href="#expertise" className="text-sm font-medium text-gray-900 hover:text-gray-600 px-3 py-2 rounded-md transition-colors duration-300">Expertise</a> */}
                    <a href="#projects" className="text-sm font-medium text-gray-900 hover:text-gray-600 px-3 py-2 rounded-md transition-colors duration-300">Projects</a>
                </div>
                </div>
                <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                    <a href="#contact" className="text-sm font-medium text-gray-900 hover:text-gray-600 px-3 py-2 rounded-md transition-colors duration-300">Contact Me</a>
                    
                </div>
                </div>
                <div className="md:hidden">
                <button onClick={toggleMenu} className="inline-flex items-center justify-center p-2 rounded-md text-gray-900 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <svg id="menu-icon" className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18"></path>
                    </svg>
                    <svg id="close-icon" className="h-6 w-6 hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>
                </div>
            </div>
            </div>

            <div id="menu" className="md:hidden hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                {/* <a href="#home" className="text-gray-900 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium">Home</a> */}
                <a href="#resume" className="text-gray-900 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium">Resume</a>
                {/* <a href="#expertise" className="text-gray-900 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium">Expertise</a> */}
                <a href="#projects" className="text-gray-900 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium">Projects</a>
                <a href="#contact" className="text-gray-900 hover:text-gray-600 block px-3 py-2 rounded-md text-base font-medium">Contact Me</a>
                
            </div>
            </div>
        </nav>
     );
}
 
export default nav;