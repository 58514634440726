import Nav from "./nav.js"
import Header from "./header.js"
import Pages from "./pages.js"
import Footer from "./footer.js"

function App() {
  return (
    <div className="App bg-blue-dark">
      <Nav />
      <div className="relative mb-10">
        <Header />
        <Pages />
      </div>
      <Footer />
    </div>
  );
}

export default App;
